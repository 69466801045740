import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _9743354a = () => interopDefault(import('../pages/check.vue' /* webpackChunkName: "pages/check" */))
const _29eed5b3 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0de64823 = () => interopDefault(import('../pages/dist.vue' /* webpackChunkName: "pages/dist" */))
const _57fe37da = () => interopDefault(import('../pages/filmmaker.vue' /* webpackChunkName: "pages/filmmaker" */))
const _4a83b9ba = () => interopDefault(import('../pages/old.vue' /* webpackChunkName: "pages/old" */))
const _29d9c61d = () => interopDefault(import('../pages/post.vue' /* webpackChunkName: "pages/post" */))
const _f743e34e = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _6c0bd4a2 = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _6c7ab425 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected
  if (to.matched.length < 2) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/check",
      component: _9743354a,
      name: "check"
    }, {
      path: "/contact",
      component: _29eed5b3,
      name: "contact"
    }, {
      path: "/dist",
      component: _0de64823,
      name: "dist"
    }, {
      path: "/filmmaker",
      component: _57fe37da,
      name: "filmmaker"
    }, {
      path: "/old",
      component: _4a83b9ba,
      name: "old"
    }, {
      path: "/post",
      component: _29d9c61d,
      name: "post"
    }, {
      path: "/pricing",
      component: _f743e34e,
      name: "pricing"
    }, {
      path: "/request",
      component: _6c0bd4a2,
      name: "request"
    }, {
      path: "/",
      component: _6c7ab425,
      name: "index"
    }],

    fallback: false
  })
}
