<template>
  <div class="page">
    <header>
      <div class="intro-block">
        <div class="logo">
          <nuxt-link to="/">
            <img src="~/assets/img/filmflow_logo_h.svg" alt="Nice Dissolve">
          </nuxt-link>
        </div>
      </div>
      <a class="log-in button clear small" href="https://app.filmflow.io">Log In</a>
    </header>
    <div>
      <ul class="tabs center fixed">
        <li v-for="tab of tabs" :key="tab[1]" :class="{selected: isCurrentRoute(tab[1])}">
          <nuxt-link :to="tab[1]">
            <div>{{ tab[0] }}</div>
          </nuxt-link>
        </li>
      </ul>
    </div>
    <nuxt/>
  </div>
</template>

<style lang="scss" scoped>
header {
  background-color: rgb(53, 53, 53);
}

.intro-block {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;

  .logo {
    flex-basis: 50%;
    text-align: center;
    img {
      height: 80px;
    }
  }
}

.tagline {
  flex-basis: 50%;
  text-align: center;
}

h2 {
  font-family: 'futura-pt', sans-serif;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 40px;
}

div.subtitle {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  font-size: 120%;
}

.tabs {
  background-color: rgb(75, 75, 75);
  font-family: 'futura-pt', sans-serif;
  > li {
    font-size: 120%;
    a {
      color: rgb(255, 255, 255);
    }
    &.selected {
      background-color: rgb(144, 214, 255) !important;
      a {
        color: rgb(34, 34, 34) !important;
      }
    }
  }
  border-bottom: none;
}

.log-in {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: bold;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
</style>

<script>
export default {
  data() {
    return {
      tabs: [['Overview', '/'], ['Pricing', '/pricing'], ['Contact Us', '/contact']]
    }
  },
  methods: {
    isCurrentRoute(path) {
      return this.$route.path === path || this.$route.path === path + '/'
    }
  }
}
</script>
