<template>
  <vue-multiselect
    v-model="selectValue"
    :custom-label="getLabel"
    :options="selectOptions"
    :placeholder="placeholderText"
    :showLabels="false"
    :disabled="disabled"
    :searchable="searchable"
  >
    <span class="no-results" slot="noResult">No matching results</span>
    <span class="no-results" slot="beforeList">
      <slot name="header"/>
      <div class="menu-header" v-if="header">{{ header }}</div>
    </span>
  </vue-multiselect>
</template>

<script>
import Utils from '@/lib/utils'
import VueMultiselect from 'vue-multiselect'

export default {
  props: {
    options: {
      required: true
    },
    value: {
      required: true
    },
    showBlank: {
      type: Boolean
    },
    subset: {},
    labelFormat: {
      type: Function
    },
    labelField: {
      default: 'name',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Select option'
    },
    searchable: {
      default: true,
      type: Boolean
    },
    header: { type: String }
  },
  components: { VueMultiselect },
  data() {
    return {}
  },
  methods: {
    getLabel(id) {
      if (this.labelFormat && id) {
        const item = this.options.find(i => i.id === id)
        return this.labelFormat(item)
      } else {
        return this.defaultLabel(id)
      }
    },
    defaultLabel(id) {
      if (Array.isArray(this.options)) {
        if (this.options.length && typeof this.options[0] === 'string') {
          // If we were just passed an array of bare strings
          return id
        } else {
          const label = this.options.find(item => item.id === id)
          if (label) return label[this.labelField]
        }
      } else {
        if (this.options[id] && this.options[id][this.labelField]) {
          return this.options[id][this.labelField]
        } else {
          return this.options[id]
        }
      }
    }
  },
  computed: {
    placeholderText() {
      return this.getLabel(this.value) || this.placeholder
    },
    selectOptions() {
      let options
      if (Array.isArray(this.options)) {
        if (this.options.length && typeof this.options[0] === 'string') {
          options = this.options
        } else {
          options = this.options.map(item => item.id)
        }
      } else {
        options = Object.keys(this.options)
      }
      if (this.value) {
        // Current value always first in options
        Utils.spliceOut(this.value, options)
        options.unshift(this.value)
      }
      return options
    },
    selectValue: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
