export default {
  spliceOut(item, array) {
    let index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1)
    }
    return array
  },
  spliceEmpty(array) {
    array.splice(0, array.length)
    return array
  },
  unique(array) {
    return Array.from(new Set(array))
  },
  findItems(object, type, id, list = []) {
    for (const k in object) {
      if (k === '_type' && object.id === id && object[k] === type) {
        list.push(object)
      } else if (typeof object[k] === 'object') {
        this.findItems(object[k], type, id, list)
      }
    }
    return list
  },
  updateItems(object, updates, parent = null, parentList = []) {
    if (Array.isArray(updates)) {
      updates = updates.reduce((map, obj) => {
        map[obj._type + obj.id] = obj
        return map
      }, {})
    }
    for (const k in object) {
      // If we're looking at a type field and an object with this id is present in the update hash table
      if (k === '_type' && object._type + object.id in updates) {
        Object.assign(object, updates[object._type + object.id])
        // If this object has a parrent, that parrent is an array, and that array isn't already in our list or parents
        if (parent && Array.isArray(parent) && parentList.indexOf(parent) === -1) {
          parentList.push(parent)
        }
      } else if (typeof object[k] === 'object') {
        // If the object in array, but its first member doesn't have a _type field, we can safely skip it
        if (!Array.isArray(object[k]) || object[k][0]['_type']) {
          this.updateItems(object[k], updates, object, parentList)
        }
      }
    }
    return parentList
  },
  deepClone(object) {
    return JSON.parse(JSON.stringify(object))
  }
}
