<template>
  <div class="multiselect">
    <ul>
      <ul name="list" tag="p">
        <li v-for="item in selectedOptions" :key="item.id">
          {{ item[itemLabelKey] }}
          <span @click="removeItem(item)">&times;</span>
        </li>
      </ul>
    </ul>
    <mf-select
      :disabled="unselectedOptions.length == 0"
      :placeholder="unselectedOptions.length == 0 ? 'No more options' : undefined"
      :options="unselectedOptions"
      :value="null"
      :searchable="searchable"
      v-on:input="addItem"
    >
      <div class="menu-header" slot="header">{{ header }}</div>
    </mf-select>
  </div>
</template>

<script>
import MfSelect from '~/components/UI/MfSelect'

export default {
  components: { MfSelect },
  props: {
    options: {
      required: true
    },
    itemLabelKey: {
      type: String,
      default: 'name'
    },
    value: {
      required: true,
      type: Array
    },
    buttonLabel: {
      type: String
    },
    searchable: {
      default: true,
      type: Boolean
    },
    header: { type: String }
  },
  computed: {
    selectedOptions() {
      if (!('value' in this) || this.value === undefined) return []
      return this.options.filter(item => this.value.includes(item.id))
    },
    unselectedOptions() {
      if (!('value' in this) || this.value === undefined) return this.options
      return this.options.filter(item => !this.value.includes(item.id))
    }
  },
  watch: {},
  methods: {
    addItem(id) {
      if (!this.value) {
        this.$emit('input', [id])
      } else {
        this.$emit('input', [...this.value, id])
      }
    },
    removeItem(item) {
      this.$emit('input', this.value.filter(id => id !== item.id))
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mf-vars.scss';

ul {
  margin: 0;
  padding: 0;
}

li {
  width: 100%;
  list-style: none;
  background-color: $token-background-color;
  margin-bottom: 0.2rem;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid $token-border-color;
}

li > span {
  float: right;
  font-size: 130%;
  cursor: pointer;
  margin-top: -0.14rem;
}
</style>
