import Vue from 'vue'
import MfSelect from '@/components/UI/MfSelect'
import MfMultiSelect from '@/components/UI/MfMultiselect'
import MfDropdown from '@/components/UI/MfDropdown'
import MfIcon from '@/components/UI/MfIcon'

Vue.component('mf-select', MfSelect)
Vue.component('mf-multiselect', MfMultiSelect)
Vue.component('mf-dropdown', MfDropdown)
Vue.component('mf-icon', MfIcon)
