<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
  >
    <g :fill="color">
      <template v-if="name === 'settings'">
        <path
          d="M38.4,23.4c0.8-0.1,1.4-0.9,1.4-1.7V18c0-0.8-0.7-1.5-1.4-1.7L34,15.6c-0.8-0.1-1.4-0.4-1.5-0.7
		c-0.1-0.2,0.1-1.3,0.6-2l2.5-3.5c0.4-0.7,0.3-1.7-0.2-2.2l-2.8-2.8c-0.6-0.6-1.5-0.7-2.2-0.2l-3.6,2.5c-0.7,0.4-1.3,0.8-1.5,0.7
		c-0.2-0.1-0.9-0.9-1.1-1.7l-0.7-4.2c0-0.8-0.8-1.4-1.5-1.4h-3.8c-0.8,0-1.5,0.7-1.7,1.4l-0.8,4.3c-0.1,0.8-0.4,1.4-0.7,1.5
		c-0.2,0.1-1.3-0.1-2-0.6L9.6,4.4C8.9,3.9,7.9,4,7.4,4.5L4.6,7.3C4.1,7.8,4,8.8,4.4,9.5L7,13.1c0.4,0.7,0.8,1.3,0.7,1.5
		c-0.1,0.2-0.9,0.9-1.7,1l-4.3,0.7c-0.8,0.1-1.4,0.9-1.4,1.7v3.8c0,0.8,0.7,1.5,1.4,1.7L6,24.2c0.8,0.1,1.4,0.4,1.5,0.7
		c0.1,0.2-0.1,1.3-0.6,2l-2.5,3.6c-0.4,0.7-0.3,1.7,0.2,2.2l2.8,2.8c0.6,0.6,1.5,0.7,2.2,0.2l3.6-2.5c0.7-0.4,1.3-0.8,1.5-0.7
		c0.2,0.1,0.9,0.9,1.1,1.7l0.8,4.3c0.1,0.8,0.9,1.4,1.7,1.4h3.8c0.8,0,1.5-0.7,1.7-1.4l0.8-4.3c0.1-0.8,0.4-1.4,0.7-1.5
		c0.2-0.1,1.3,0.1,2,0.6l3.6,2.5c0.7,0.4,1.7,0.3,2.2-0.2l2.8-2.8c0.6-0.6,0.7-1.5,0.2-2.2l-2.5-3.6c-0.4-0.7-0.8-1.3-0.7-1.5
		c0.1-0.2,0.9-1,1.7-1.1L38.4,23.4z M20,27.8c-4.4,0-8-3.5-8-8s3.5-8,8-8s8,3.5,8,8S24.4,27.8,20,27.8z"
        ></path>
      </template>

      <template v-if="name === 'vertical-drag'">
        <path
          d="M20.8,0.6c-0.5-0.8-1.2-0.8-1.6,0l-8.4,14.6c-0.5,0.8-0.1,1.4,0.8,1.4h16.8c0.9,0,1.2-0.6,0.8-1.4L20.8,0.6z"
        ></path>
        <path
          d="M20.8,39.4c-0.4,0.8-1.1,0.8-1.6,0l-8.4-14.6c-0.4-0.8-0.1-1.4,0.8-1.4h16.8c0.9,0,1.3,0.6,0.8,1.4L20.8,39.4z"
        ></path>
      </template>

      <template v-if="name === 'attachment'">
        <path
          d="M20.1,0l-0.2,0c-5,0.1-9,4.1-9,9.1l0,23.1c0,1.7,0.6,4.3,2.1,5.7c1.3,1.3,3.1,2.1,5,2.1c1.9,0,3.7-0.7,5-2.1
				l0,0c1.3-1.3,2.1-3.1,2.1-5L25,13c0-1.4-0.6-2.7-1.6-3.6C22.5,8.5,21.3,8,20.1,8c-1.3,0-2.6,0.5-3.5,1.4
				c-0.9,0.9-1.5,2.2-1.5,3.5l0,15.3c0,0.4,0.1,0.8,0.4,1.1c0.6,0.6,1.5,0.6,2.1,0c0.3-0.3,0.4-0.7,0.4-1l0,0l0-15.3
				c0-0.5,0.2-1.1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6c0.5,0,1,0.2,1.4,0.5c0.4,0.4,0.6,0.9,0.6,1.5L22,33c0,1.1-0.4,2.1-1.2,2.9l0,0
				c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2c-0.6-0.6-1.2-2.3-1.2-3.6l0-23.1C13.9,5.8,16.6,3,20,3h0.2
				c3.3,0.1,6.1,2.8,6,6.2l0,19.1c0,0.4,0.1,0.8,0.4,1.1c0.6,0.6,1.5,0.6,2.1,0c0.3-0.3,0.4-0.7,0.4-1l0,0l0-19.1
				C29.2,4.1,25.2,0.1,20.1,0z"
        ></path>
      </template>

      <template v-if="name === 'check'">
        <path
          d="M13.7,34L1,21.3c-0.8-0.8-0.8-2,0-2.8l2.8-2.8c0.8-0.8,2-0.8,2.8,0l8.6,8.6L33.5,6c0.8-0.8,2-0.8,2.8,0L39,8.7
	c0.8,0.8,0.8,2,0,2.8L16.5,34C15.7,34.8,14.5,34.8,13.7,34L13.7,34z"
        ></path>
      </template>

      <template v-if="name === 'new-doc'">
        <path
          d="M23.5,12.5h-1.3v-1.3V0H7C6.1,0,5.2,0.8,5.2,1.8v36.4c0,1,0.9,1.8,1.8,1.8h26c0.9,0,1.8-0.8,1.8-1.8V12.5H23.5z
	 M27.1,26.9h-5.6v5.6h-3v-5.6h-5.6v-3h5.6v-5.6h3v5.6h5.6V26.9z"
        ></path>
        <polyline points="23.5,0 23.5,11.2 34.8,11.2 "></polyline>
      </template>

      <template v-if="name === 'download'">
        <path
          d="M37.1,19.4L21.3,39.1c-0.7,0.8-1.9,0.8-2.6,0L2.9,19.4c-0.7-0.8-0.4-1.6,0.8-1.6h8.2V2.3c0-1.1,0.8-2,1.8-2
	h12.8c1,0,1.8,1,1.8,2v15.4h8C37.4,17.9,37.8,18.5,37.1,19.4z"
        ></path>
      </template>

      <template v-if="name === 'upload'">
        <path
          d="M36.8,21L21,1.3c-0.7-0.8-1.9-0.8-2.6,0L2.7,21c-0.7,0.8-0.4,1.6,0.8,1.6h8.2V38c0,1.1,0.8,2,1.8,2h12.8
	c1,0,1.8-1,1.8-2V22.6h8C37.1,22.6,37.5,21.9,36.8,21z"
        ></path>
      </template>

      <template v-if="name === 'info'">
        <path
          d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M21.8,27.7v1c-0.2,0.2-0.5,0.3-0.8,0.5
	c-0.3,0.2-0.7,0.3-1,0.4s-0.7,0.2-1.1,0.3s-0.7,0.1-1.1,0.1c-0.7,0-1.2-0.1-1.5-0.4c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.4,0-0.7,0.1-1.1
	c0.1-0.4,0.1-0.7,0.2-1.2l1.9-8.6l-1.7-0.4v-0.9c0.3-0.1,0.6-0.2,1.1-0.3c0.5-0.1,1-0.2,1.5-0.3c0.5-0.1,1-0.2,1.5-0.2
	c0.5-0.1,1-0.1,1.3-0.1l0.5,0.3l-2.5,11.9H21.8z M22.7,12.6c-0.4,0.4-1,0.5-1.7,0.5c-0.6,0-1.2-0.2-1.6-0.5
	c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,1-0.5,1.6-0.5c0.7,0,1.3,0.2,1.7,0.5c0.4,0.4,0.6,0.8,0.6,1.5
	C23.3,11.7,23.1,12.2,22.7,12.6z"
        ></path>
      </template>

      <template v-if="name === 'duplicate'">
        <path
          class="st0"
          d="M0,13.7c0-0.9,0.8-1.7,1.7-1.7h24.6c0.9,0,1.7,0.8,1.7,1.7v24.6c0,0.9-0.8,1.7-1.7,1.7H1.7
		C0.8,40,0,39.2,0,38.3V13.7z"
        ></path>
        <path
          class="st0"
          d="M38.3,0H13.7c-0.9,0-1.7,0.8-1.7,1.7v3.9v2.8v1.7h1.7h14.5c0.9,0,1.7,0.8,1.7,1.7v14.5v1.7h1.7h2.8h3.9
	c0.9,0,1.7-0.8,1.7-1.7V1.7C40,0.8,39.2,0,38.3,0z"
        ></path>
      </template>
    </g>
  </svg>
</template

    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    color: {
      type: String,
      default: '#939598'
      // currentColor will match surrounding text
    }
  }
}
</script>

<style lang="scss">
</style>
