<template>
  <div class="dropdown">
    <div class="label">{{ label }}</div>
    <mf-select
      :disabled="disabled"
      :placeholder="''"
      :options="options"
      :value="null"
      :searchable="false"
      v-on:input="itemChosen"
    >
      <div class="menu-header" slot="header">{{ header }}</div>
    </mf-select>
  </div>
</template>

<script>
import MfSelect from '~/components/UI/MfSelect'

export default {
  components: { MfSelect },
  data() {
    return {}
  },
  props: {
    options: { type: Array, required: true },
    label: { type: String, required: true },
    header: { type: String },
    itemLabelKey: {
      type: String,
      default: 'name'
    },
    disabled: Boolean,
    variant: String,
    labelLookup: {
      type: [Boolean, Object],
      default: false
    },
    emitId: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    itemChosen(value) {
      if (this.emitId) {
        this.$emit('input', value)
      } else {
        const item = this.options.find(i => i.id === value)
        if (item) {
          this.$emit('input', item)
        } else {
          this.$emit('input', value)
        }
      }
    }
  },
  filters: {}
}
</script>

<style lang="scss">
@import '@/assets/style/mf-vars.scss';
</style>
